// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/root.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
var _s = $RefreshSig$(),
  _s2 = $RefreshSig$();
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/root.tsx");
}
// REMIX HMR END

import { ChakraProvider, extendTheme, useToast } from "@chakra-ui/react";
import { withEmotionCache } from "@emotion/react";
// Depends on the runtime you choose
import { Links, LiveReload, Meta, Outlet, Scripts, ScrollRestoration, useLocation, useNavigate } from "@remix-run/react";
import React, { useContext, useEffect } from "react";
import appStylesHref from "./app.css";
import { withProse } from "@nikolovlazar/chakra-ui-prose";
import { ClientStyleContext, ServerStyleContext } from "./context";
const config = {
  initialColorMode: "system"
};
const theme = extendTheme({
  config,
  fonts: {
    heading: `'Inter', sans-serif`,
    body: `'Inter', sans-serif`
  },
  breakpoints: {
    base: "0em",
    // 0px
    xs: "30em",
    // ~480px
    sm: "48em",
    // ~800px
    md: "70em",
    // ~1120px
    lg: "90em",
    // ~1440px
    xl: "110em",
    // ~1760px
    "2xl": "130em",
    // ~2080px
    "3xl": "160em",
    // ~2560px
    "4xl": "200em" // ~3200px
  },
  styles: {
    global: props => ({
      body: {
        bg: props.colorMode === "dark" ? "gray.900" : "white"
      }
    })
  }
}, withProse({
  baseStyle: {
    p: {
      my: 2,
      letterSpacing: "wide"
    },
    a: {
      textDecoration: "underline"
    }
  }
}));
export let links = () => {
  return [{
    rel: "stylesheet",
    href: appStylesHref
  }, {
    rel: "preconnect",
    href: "https://fonts.googleapis.com"
  }, {
    rel: "preconnect",
    href: "https://fonts.gstatic.com"
  }, {
    rel: "stylesheet",
    href: "https://fonts.googleapis.com/css2?family=Inter:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&display=swap"
  }];
};
const Document = _s(withEmotionCache(_c = _s(({
  children
}, emotionCache) => {
  _s();
  const serverStyleData = useContext(ServerStyleContext);
  const clientStyleData = useContext(ClientStyleContext);

  // Only executed on client
  useEffect(() => {
    // re-link sheet container
    emotionCache.sheet.container = document.head;
    // re-inject tags
    const tags = emotionCache.sheet.tags;
    emotionCache.sheet.flush();
    tags.forEach(tag => {
      emotionCache.sheet._insertTag(tag);
    });
    // reset cache to reapply global styles
    clientStyleData?.reset();
  }, []);
  return <html lang="en">
        <head>
          <meta charSet="utf-8" />
          <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1" />

          <title>Uprate - AI powered Hosts for Retail Brands</title>
          <meta name="description" content="Our hosts are powered by the latest AI infrastructure and are always ready to help guests wherever they are" />

          <meta property="og:title" content="Uprate - AI powered Hosts" />
          <meta property="og:description" content="AI powered Hosts for Retail Brands" />

          <meta property="og:image" content="/assets/logo/logo-circle.png" />
          <Meta />
          <Links />
          {serverStyleData?.map(({
        key,
        ids,
        css
      }) => <style key={key} data-emotion={`${key} ${ids.join(" ")}`} dangerouslySetInnerHTML={{
        __html: css
      }} />)}
        </head>
        <body>
          {children}
          <ScrollRestoration />
          <Scripts />
          <LiveReload />
        </body>
      </html>;
}, "vFrPjx9OU/CyW6kig5dfFtYNdnQ=")), "vFrPjx9OU/CyW6kig5dfFtYNdnQ=");
_c2 = Document;
export default function App() {
  _s2();
  const toast = useToast();
  const location = useLocation();
  const navigate = useNavigate();
  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    if (queryParams.has("success")) {
      let toastMessage = "Success";
      const message = queryParams.get("success") || "";
      if (message != "") {
        const toastMessages = {
          hostCreated: "New host created",
          hostDeleted: "Host deleted",
          behaviorAdded: "Behavior added",
          behaviorUpdated: "Behavior updated",
          behaviorRemoved: "Behavior removed",
          customFileUploaded: "File uploaded",
          customFileRemoved: "File removed",
          buildCreated: "Build created",
          buildActivated: "Build activated"
        };
        toastMessage = toastMessages[message] || "Success";
      }
      toast({
        description: toastMessage,
        status: "success",
        duration: 3000,
        isClosable: true
      });
      navigate(window.location.pathname, {
        replace: true
      });
    }
  }, [location.pathname, location.search, navigate, toast]);
  return <Document>
      <ChakraProvider theme={theme}>
        <Outlet />
      </ChakraProvider>
    </Document>;
}
_s2(App, "6afpuLOPSqaTw1pUD8KMI9XS8XM=", false, function () {
  return [useToast, useLocation, useNavigate];
});
_c3 = App;
var _c, _c2, _c3;
$RefreshReg$(_c, "Document$withEmotionCache");
$RefreshReg$(_c2, "Document");
$RefreshReg$(_c3, "App");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;